import { ElementType, PropsWithChildren } from 'react'
import { Box } from '@resident-advisor/design-system'
import styled, { css } from 'styled-components'
import { SpaceProps } from 'styled-system'

// TODO: Make this dynamic with context https://codesandbox.io/s/jvzz0n45v5
// Section and SubSection would likely need refactoring though
const Heading = ({
  children,
  level = 3,
  hide,
  ...props
}: PropsWithChildren<HeadingProps>) => (
  // as ElementType: https://stackoverflow.com/questions/67523515/this-jsx-tags-children-prop-expects-type-never-which-requires-multiple-chil?rq=3
  <StyledBox hide={hide} as={`h${level}` as ElementType} {...props}>
    {children}
  </StyledBox>
)

// Hide was taken from theguardian.com CSS
const StyledBox = styled(Box)`
  ${({ hide }) =>
    hide &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      height: 0.0625rem;
      margin: -0.0625rem;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0.0625rem;
    `}
`

type HeadingProps = {
  level?: number
  hide?: boolean
  width?: string
  m?: SpaceProps['m']
  pb?: SpaceProps['pb']
  pt?: SpaceProps['pt']
  pr?: SpaceProps['pr']
}

export default Heading
