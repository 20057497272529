import PropTypes from 'prop-types'
import {
  Alignment,
  Text,
  FaceFlatIcon,
  SubSection,
  variants,
  Box,
  Icon,
} from '@resident-advisor/design-system'
import errorMessages from 'messages/errors'
import { useIntl } from 'react-intl'
import { useThemeContext } from 'hooks/useThemeContext'
import testIds from 'enums/testIds'

// This component is used in the QueryResultHandler as a function
// so we need to have a default (empty) props object for destructuring
const NoResults = ({
  title,
  message,
  subSectionProps = {},
}: NoResultsProps = {}) => {
  const intl = useIntl()
  const theme = useThemeContext()

  return (
    <SubSection
      data-testid={testIds.genericNoResults}
      pt={{ s: 6, m: 7 }}
      pb={6}
      {...subSectionProps}
      variant={variants.section.secondary}
    >
      <Alignment
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <Icon
            Component={FaceFlatIcon}
            size={180}
            color={theme.noResults.icon}
          />
        </Box>
        <Text variant={variants.text.heading.xl}>
          {title ?? intl.formatMessage(errorMessages.noResults)}
        </Text>
        <Text pt={4}>
          {message ?? intl.formatMessage(errorMessages.noResultsTryAgain)}
        </Text>
      </Alignment>
    </SubSection>
  )
}

type NoResultsProps = {
  title?: string
  message?: string
  subSectionProps?: object
}

NoResults.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

export default NoResults
